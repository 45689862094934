import './css/nosotros.css';
import image from './img/1.png'; // Asegúrate de que la imagen esté en la carpeta correcta
import image2 from './img/2.png'; 

function Nosotros() {
  return (
    <div className='nosotros_a' id="nosotros">
      {/* Imagen a la izquierda, detrás del texto */}
      <img src={image} alt="Imagen lateral izquierda" className="side-image" />
      <img src={image} alt="Imagen lateral 2 izquierda" className="side-image2" />

      <div className='nosotros_c'>
        <p>
          En UMDAM nos definimos como el punto central donde convergen diversas tecnologías, plataformas e ideas. 
          Nuestra misión es crear soluciones integradas y colaborativas que impulsen la innovación y el desarrollo en el ámbito tecnológico.
          Nexus no es solo un nombre, es un reflejo de nuestro enfoque y filosofía. Actuamos como un enlace crucial entre múltiples disciplinas 
          y áreas de expertise, conectando a nuestros clientes con las herramientas y estrategias más avanzadas para alcanzar sus objetivos. 
          Nos dedicamos a unir diferentes componentes tecnológicos, asegurando que cada proyecto que emprendemos esté respaldado por un entendimiento profundo 
          y una implementación precisa.
        </p>
        <ul className='lista_container'>
          <li><a href="#founders">Founders</a></li>
          <li><a href="#proyectos">Proyectos</a></li>
        </ul>
      </div>
    </div>
  );
}

export { Nosotros };
